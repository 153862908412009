<template>
  <div class="cEditor">
    <div>
      <lh-button @click="deduct(false)">扣除</lh-button>
      <lh-button @click="deduct(true)">反向扣除</lh-button>
      <lh-button @click="revoke">撤销</lh-button>
      <lh-button @click="reset">重置</lh-button>
      <lh-button @click="save">保存</lh-button>
    </div>
    <canvas class="canvas" ref="canvas" @click="canvasClick" :width="size+'px'" :height="size+'px'"></canvas>
  </div>
</template>

<script>
import {uInitCChar,uExecuteOprate} from "../utils/index";
export default {
  name:"CEditor",
  data(){
    return{
      opList:null
    }
  },
  props:{
    oprateList:{
      type:Array,
      default:null
    },
    cChar:{
      type:String,
      default:""
    },
    size:{
      type:Number,
      default:200
    }
  },

  methods:{
    getContext:function(){
      return this.$refs.canvas.getContext("2d");
    },
    deduct:function(reverse){
      let oprate=this.opList[this.opList.length-1];
      if(oprate.oprate!=null){
        return;
      }
      if(reverse){
        oprate.oprate="rDeduct";
      }else{
        oprate.oprate="deduct";
      }
      oprate.size=this.$refs.canvas.height;
      this.loadOprate();
    },
    canvasClick:function(event){
      console.log("cClick");
      if(this.opList==null){
        this.opList=[];
      }
      if(this.opList.length==0){
        this.opList.push({oprate:null,pointList:[]});
      }
      let oprate=this.opList[this.opList.length-1];
      if(oprate.oprate!=null){
        oprate={oprate:null,pointList:[]};
        this.opList.push(oprate);
      }
      let point={x:0,y:0};
      point.x=event.offsetX;
      point.y=event.offsetY;
      oprate.pointList.push(point);
      this.drawPoint();
    },
    drawPoint:function(){
      this.connectPoints();
      if(this.opList==null||this.opList.length==0){
        return;
      }
      let endOp=this.opList[this.opList.length-1];
      if(endOp.oprate==null){
        const ctx=this.getContext();
        for(let i=0;i<endOp.pointList.length;i++){
          ctx.beginPath();
          ctx.arc(endOp.pointList[i].x,endOp.pointList[i].y,2,0,2*Math.PI);
          ctx.fillStyle="red";
          ctx.fill();
          ctx.closePath();
        }
      }
    },
    connectPoints:function(){
      if(this.opList==null||this.opList.length==0){
        return;
      }
      let oprate=this.opList[this.opList.length-1];
      if(oprate.oprate!=null||oprate.pointList.length==0){
        return;
      }
      let ctx=this.getContext();
      ctx.beginPath();
      ctx.moveTo(oprate.pointList[0].x,oprate.pointList[0].y);
      for(let i=1;i<oprate.pointList.length;i++){
        ctx.lineTo(oprate.pointList[i].x,oprate.pointList[i].y);
      }
      ctx.setLineDash([5,5]);
      ctx.strokeStyle="blue";
      ctx.lineWidth=2;
      ctx.stroke();
      ctx.closePath();
    },
    loadOprate:function(){
      let canvas=this.$refs.canvas;
      uInitCChar(canvas,this.cChar);
      uExecuteOprate(canvas,this.opList);
      // if(this.opList!=null){
      //   for(let i=0;i<this.opList.length;i++){
      //     let oprate=this.opList[i];
      //     if(oprate.oprate=="deduct"){
      //       uDeduct(this.$refs.canvas,oprate.pointList);
      //     }else{
      //       uRDeduct(this.$refs.canvas,oprate.pointList);
      //     }
      //   }
      // }
    },
    reset:function(){
      const canvas=this.$refs.canvas;
      // canvas.style.background="rgba(255,255,255,0)";
      // let ctx=this.getContext();
      // ctx.clearRect(0,0,this.size,this.size);
      // // 将文本绘制到画布上
      // ctx.font = "bold "+this.size+"px '楷体'";
      // ctx.fillStyle = 'black';
      // ctx.fillText(this.cChar, 0, this.size*0.85);
      uInitCChar(canvas,this.cChar);
      this.opList=null;
    },
    revoke:function(){
      if(this.opList==null||this.opList.length==0){
        return;
      }
      let endOp=this.opList[this.opList.length-1];
      if(endOp.oprate!=null){
        this.opList.pop();
      }else{
        endOp.pointList.pop();
      }

      this.loadOprate();
      this.drawPoint();
    },
    save:function(){
      if(this.opList==null){
        return;
      }
      this.$emit("save",this.opList);
    }
  },

  watch:{
    cChar:{
      immediate:true,
      handler(){
        //console.log("cchar");
        this.$nextTick(()=>{
          this.loadOprate();
        })
      }
    },
    oprateList:{
      immediate:true,
      handler(newVal){
        this.$nextTick(()=>{
          this.opList=newVal;
          this.loadOprate();
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cEditor{
  .canvas{
    border: solid;
  }
}
</style>