<template>
  <div class="test">
    test
    <!-- <canvas ref="canvas1" width="100" height="100"></canvas>
    <input type="text" id="textInput" placeholder="请输入文本">
    <button @click="convertToImage">转换为图片</button> -->
    <!-- <img id="imageOutput" width="100" height="100"/> -->
    <!-- <div class="cs">测试</div> -->
    <!-- <c-editor cChar="测"></c-editor> -->
    <c-index></c-index>
  </div>
</template>

<script>
import CIndex from './component/CIndex.vue';
//import CEditor from './component/CEditor.vue';
export default {
  components: { CIndex },
  //components: { CEditor },
    name:"Test",
    methods:{
        convertToImage:function() {
            // 获取输入框的文本
            const text = document.getElementById('textInput').value;
        
            // 创建一个新的Canvas元素，用于绘制文本
            //const canvas = document.createElement('canvas');
            const canvas=this.$refs.canvas1;
            // 背景设置成透明
            canvas.style.background = 'rgba(255, 255, 255, 0)'
            canvas.width = 100;
            canvas.height = 100;
        
            // 获取Canvas的2D绘图上下文
            const ctx =this.$refs.canvas1.getContext('2d');

            
        
            // 将文本绘制到画布上
            ctx.font = 'bold 100px "楷体"';
            ctx.fillStyle = 'black';
        
            ctx.fillText(text, 0, canvas.height*0.85);

            this.updateColor(canvas);

            ctx.beginPath();
            ctx.moveTo(0,0);
            ctx.lineTo(50,0);
            ctx.lineTo(50,50);
            ctx.lineTo(0,50);
            ctx.closePath();
            ctx.clip();
            ctx.clearRect(0,0,100,100);

            // 将画布转换为图片并显示
            const imgData = canvas.toDataURL('image/png');
            document.getElementById('imageOutput').src = imgData;
        },
        updateColor:function(canvas){
            // 获取Canvas的2D绘图上下文
            const ctx = canvas.getContext('2d');
            // 获取图像的像素数据
            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let data = imageData.data;

            // 修改像素数据中的颜色，这里简单地对每个像素进行颜色调整
            for (var i = 0; i < data.length; i += 4) {
                // let r=data[i]; // R
                // let g=data[i + 1];  // G
                // let b=data[i + 2];  // B
                //let a=data[i + 3];  // A

                data[i+1]=195;

            }

            // 将修改后的像素数据重新绘制到canvas上
            ctx.putImageData(imageData, 0, 0);
        }
        // rgbToHsv:function(r, g, b) {
        //     (r /= 255), (g /= 255), (b /= 255);
        //     let max = Math.max(r, g, b), min = Math.min(r, g, b);
        //     let h, s, v = max;
        //     let d = max - min;
        //     s = max === 0 ? 0 : d / max;
        //     if (max === min) {
        //         h = 0; // achromatic
        //     } else {
        //         switch (max) {
        //         case r:
        //             h = (g - b) / d + (g < b ? 6 : 0);
        //             break;
        //         case g:
        //             h = (b - r) / d + 2;
        //             break;
        //         case b:
        //             h = (r - g) / d + 4;
        //             break;
        //         }
        //         h /= 6;
        //     }
        //     return {h:h,s:s,v:v};
        // },
        // hsvToRgb:function(h, s, v) {
        //     let r, g, b; 
        //     if (s === 0) {
        //         // achromatic (grey)
        //         r = g = b = v;
        //         return {h:Math.round(r * 255),s:Math.round(g * 255),v:Math.round(b * 255)};
        //     }
            
        //     h /= 60; // sector 0 to 5
        //     let i = Math.floor(h);
        //     let f = h - i; // factorial part of h
        //     let p = v * (1 - s);
        //     let q = v * (1 - s * f);
        //     let t = v * (1 - s * (1 - f));
            
        //     switch (i) {
        //         case 0:
        //             r = v;
        //             g = t;
        //             b = p;
        //             break;
        //         case 1:
        //             r = q;
        //             g = v;
        //             b = p;
        //             break;
        //         case 2:
        //             r = p;
        //             g = v;
        //             b = t;
        //             break;
        //         case 3:
        //             r = p;
        //             g = q;
        //             b = v;
        //             break;
        //         case 4:
        //             r = t;
        //             g = p;
        //             b = v;
        //             break;
        //         default: // case 5:
        //             r = v;
        //             g = p;
        //             b = q;
        //     }
        //     return {h:Math.round(r * 255),s:Math.round(g * 255),v:Math.round(b * 255)};
        // }
    }
}
</script>

<style lang="less" scoped>
.test{
    .cs{
        font:bold 30px "楷体";
    }
    img{
        border: solid;
    }
}
</style>