export const uDeduct=function(canvas,pointList){
    if(pointList.length<3){
        return;
    }
    let ctx=canvas.getContext("2d");
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(pointList[0].x,pointList[0].y);
    for(let i=1;i<pointList.length;i++){
        ctx.lineTo(pointList[i].x,pointList[i].y);
    }
    ctx.closePath();
    ctx.clip();
    ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.restore();
}

/**
 * 判断点集是顺时针还是逆时针
 * 鞋带公式
 * @param {*} pointList 
 */
const clockwise=function(pointList){
    let area=0;
    for(let i=0;i<pointList.length-1;i++){
        area+=0.5*(pointList[i].x*pointList[i+1].y-pointList[i+1].x*pointList[i].y);
    }
    return area>0?true:false;
}

export const uRDeduct=function(canvas,pointList){
    if(pointList.length<3){
        return;
    }
    let ctx=canvas.getContext("2d");
    ctx.save();
    ctx.beginPath();
    ctx.rect(0,0,canvas.width,canvas.height);
    let c=clockwise(pointList);
    if(c){
        let endIndex=pointList.length-1;
        ctx.moveTo(pointList[endIndex].x,pointList[endIndex].y);
        for(let i=endIndex-1;i>=0;i--){
            ctx.lineTo(pointList[i].x,pointList[i].y);
        }
    }else{
        ctx.moveTo(pointList[0].x,pointList[0].y);
        for(let i=1;i<pointList.length;i++){
            ctx.lineTo(pointList[i].x,pointList[i].y);
        }
    }
    ctx.closePath();
    ctx.clip();
    ctx.beginPath();
    ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.restore();
}

export const uInitCChar=function(canvas,cChar){
    canvas.style.background="rgba(255,255,255,0)";
    let ctx=canvas.getContext("2d");
    ctx.clearRect(0,0,canvas.width,canvas.height);
    // 将文本绘制到画布上
    ctx.font = "bold "+canvas.height+"px '楷体'";
    //ctx.font =canvas.height+"px '楷体'";
    ctx.fillStyle = 'black';
    ctx.fillText(cChar, 0,canvas.height*0.85);
}

export const uExecuteOprate=function(canvas,oprateList){
    if(oprateList==null||oprateList.length==0){
        return;
    }
    for(let i=0;i<oprateList.length;i++){
        let pointList=[];
        for(let j=0;j<oprateList[i].pointList.length;j++){
            let point={x:0,y:0};
            let p=oprateList[i].pointList[j];
            let size=oprateList[i].size;
            point.x=p.x*(canvas.width/size);
            point.y=p.y*(canvas.height/size);
            pointList.push(point);
        }
        if(oprateList[i].oprate=="deduct"){
            uDeduct(canvas,pointList);
        }else if(oprateList[i].oprate=="rDeduct"){
            uRDeduct(canvas,pointList);
        }
    }
}

export const uUpdateColor=function(canvas,rgb){
    console.log(rgb);
    // let index=0;
    // switch(rgb){
    //     case "r":
    //         index=0;
    //         break;
    //     case "g":
    //         index=1;
    //         break;
    //     case "b":
    //         index=2;
    //         break;
    // }
    // 获取Canvas的2D绘图上下文
    const ctx = canvas.getContext('2d');
    // 获取图像的像素数据
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;

    // 修改像素数据中的颜色，这里简单地对每个像素进行颜色调整
    for (var i = 0; i < data.length; i += 4) {
        // let r=data[i]; // R
        // let g=data[i + 1];  // G
        // let b=data[i + 2];  // B
        //let a=data[i + 3];  // A

        //data[i+index]=195;
        data[i]=0;
        data[i+1]=255;
        data[i+2]=0;

    }

    // 将修改后的像素数据重新绘制到canvas上
    ctx.putImageData(imageData, 0, 0);
}

export const punctuation=/[，。？]/;
export const notPunctuation=/[^，^。^？]/g;