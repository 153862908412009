<template>
  <div class="cIndex">
    <div class="oprate">
        <lh-button accept=".png,.jpg" @change="selectB">选择背景</lh-button>
        <lh-button @click="startSetType">开始排版</lh-button>
        <lh-button @click="getGif">生成gif</lh-button>
        <textarea v-model="text"></textarea>
        <lh-table v-if="pList">
            <lh-tr>
                <lh-th></lh-th>
                <lh-th>是否居中</lh-th>
                <lh-th>字体大小</lh-th>
            </lh-tr>
            <lh-tr v-for="(item,i) of pList" :key="i">
                <lh-td>段{{ i+1 }}</lh-td>
                <lh-td>
                    <lh-checkbox v-model="item.center"></lh-checkbox>
                </lh-td>
                <lh-td>
                    <lh-select v-model="item.size">
                        <lh-option v-for="j of 10" :value="(25+j*2)" :key="j">{{ 25+j*2 }}</lh-option>
                    </lh-select>
                </lh-td>
            </lh-tr>
        </lh-table>
    </div>
    <div class="imgShow" ref="image" :style="{width:width+'px',height:height+'px'}">
        <img v-if="image" :width="width+'px'" :src="image.src"/>
        <div style="margin:10px" v-if="pList">
            <!-- <div class="row" @click="test" :style="{justifyContent:'center'}">
                <c-cell ref="cell" cChar="测" :size="15"></c-cell>
            </div> -->
            <div class="row" v-for="(pItem,i) of pList" :key="i" :style="{justifyContent:pItem.center?'center':'flexStart'}">
                <c-cell v-if="!pItem.center" cChar="" :size="pItem.size"></c-cell>
                <c-cell v-if="!pItem.center" cChar="" :size="pItem.size"></c-cell>
                <c-cell v-for="(cItem,j) of pItem.cCharList" :cChar="cItem.cChar" :ref="'r'+i+'c'+j" :numNum="cItem.numNum" :size="pItem.size" :key="j"></c-cell>
            </div>
        </div>
    </div>
    <img :src="gifUrl"/>
    <!-- <canvas ref="canvas" :width="width+'px'" :height="height+'px'"></canvas> -->
  </div>
</template>

<script>
import CCell from './CCell.vue';
import {punctuation,notPunctuation} from "../utils/index"
export default {
  components: { CCell },
    name:"CIndex",
    data(){
        return{
            width:700,
            height:400,
            image:null,
            //size:23,
            text:"",
            pList:null,
            gifUrl:null
        }
    },
    methods:{
        selectB:function(e){
            let file=e.target.files[0];
            if(!file) return;
            const reader=new FileReader();
            reader.onload=(event)=>{
                const img=new Image();
                img.onload=()=>{
                    this.image=img;
                    console.log("width",img.height);
                    this.height=img.height*(this.width/img.width);
                    img.width=this.width;
                    // this.$nextTick(()=>{
                    //     this.changeBackground();
                    // })
                };
                img.src=event.target.result;
            }
            reader.readAsDataURL(file);
        },
        changeBackground:function(){
            let canvas=this.$refs.canvas;
            let ctx=canvas.getContext("2d");
            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.drawImage(this.image,0,0,this.width,this.height);
        },
        startSetType:function(){
            let ptList=this.text.split(/[\n]/);
            let pList=[];
            let ruleList=[
                [2],
                [3,3],
                [2,2,2],
                [2,1,1,2],
                [2,1,1,1,1]
            ]
            for(let pt of ptList){
                let pItem={center:false,size:27,cCharList:[]};
                let stList=pt.split(punctuation);
                let puList=pt.replace(notPunctuation,"");
                console.log("pu",puList);
                console.log("st",stList);
                for(let j=0;j<stList.length;j++){
                    let st=stList[j];
                    let rule=ruleList[4];
                    if(st.length<6){
                        rule=ruleList[st.length-1];
                    }
                    for(let i=0;i<st.length;i++){
                        let cItem={};
                        cItem.cChar=st[i];
                        if(i>4){
                            cItem.numNum=0;
                        }else{
                            cItem.numNum=rule[i];
                        }
                        pItem.cCharList.push(cItem);
                    }
                    if(j<puList.length){
                        let cItem={};
                        cItem.cChar=puList[j];
                        cItem.numNum=0;
                        pItem.cCharList.push(cItem);
                    }
                }
                this.pList=pList;
                pList.push(pItem);
            }
            console.log("pList",pList);
        },
        getGifByImageList:function(imageList){
            let that=this;
            let p=new Promise((resolve,reject)=>{
                window.gifshot.createGIF({
                    'gifWidth':that.width,
                    'gifHeight':that.height,
                    'numFrames':1,
                    // 'text':'f',
                    // 'fontColor':'rgb(0,255,0)',
                    'frameDuration':10,
                    'images': imageList
                },function(obj){
                    if(!obj.error){
                        let image=obj.image;
                        resolve(image);
                        return;
                    }
                    reject(obj.error);
                });
            })
            return p;
        },
        getGif:async function(){
            let numC=this.$refs['r0c0'][0].getNumCanvas(0);
            //console.log("cell",);
            console.log("image",this.$refs.image.offsetLeft);
            let gif=new window.GIF({
                workers:20,
                quality:1,
                // background:"#fff",
                // transparent:"#fff",
                // dither:"Stucki-serpentine",
                workerScript:"/gif.worker.js"
            })
            //https://www.iloveimg.com/zh-cn/download/qj33nptkfr6w0cx020wj1w9cyyvl3l7q6chvc74Abrv99w89mqc56ksj28wgl6cfxz7A9l28Ay26xsA6xhsfj1rlk36khl4z0y4q2hpfjxA8n6v91b5m5y22ccg2rnvrcqhA2vbshyk33ns5w5svmdxyx0lryvnjqwA541486ghfmkcr2n01/4
            let canvas=document.createElement("canvas");
            canvas.width=this.width;
            canvas.height=this.height;
            canvas.style.background="rgb(255,255,255)";
            console.log("offt",numC.offsetTop);
            console.log("offl",numC.offsetLeft);
            let ctx=canvas.getContext("2d");
            ctx.drawImage(this.image,0,0,this.width,this.height);
            ctx.fillStyle="rgb(0,255,0)";
            ctx.fillRect(0,0,5,this.height);
            //let url=canvas.toDataURL("image/png");
            let charc=this.$refs['r0c0'][0].getCharCanvas();
            ctx.drawImage(charc,charc.offsetLeft,charc.offsetTop);
            //let url2=canvas.toDataURL("image/png");
            // let that=this;
            // // let gif1=null;
            // // let gif2=null;
            // let imageList=[];
            // for(let i=0;i<60;i++){
            //     imageList.push(url);
            //     imageList.push(url2);
            // }
            // await this.getGifByImageList(imageList).then((data)=>{
            //     that.gifUrl=data;
            // });
            // await this.getGifByImageList([url,url2]).then((data)=>{
            //     gif2=data;
            // });
            // window.gifshot.createGIF({
            //     'gifWidth':that.width,
            //     'gifHeight':that.height,
            //     // 'numFrames':1,
            //     // 'text':'f',
            //     // 'fontColor':'rgb(0,255,0)',
            //     'frameDuration':10,
            //     'images': [gif1,gif2]
            // },function(obj){
            //     if(!obj.error){
            //         let image=obj.image;
            //         that.gifUrl=image;
            //     }
            // });
            console.log("ffff");
            //gif.addFrame(canvas,{copy:true});
            
            
            //gif.addFrame(canvas,{copy:true,delay:1000});

            ctx.drawImage(numC,numC.offsetLeft,numC.offsetTop);
            console.log("aa");
            //gif.addFrame(canvas,{copy:true,delay:1000});

            numC=this.$refs['r0c0'][0].getNumCanvas(1);
            
            gif.addFrame(canvas,{copy:true});
            for(let i=0;i<120;i++){
                ctx.drawImage(numC,numC.offsetLeft,numC.offsetTop);
                gif.addFrame(canvas,{copy:true,delay:1000});
            }
            gif.render();
            console.log("bbb");
            let that=this;
            gif.on("finished",function(blob){
                that.gifUrl=URL.createObjectURL(blob);
            })
            // console.log("cc");

        }
    }
}
</script>

<style lang="less" scoped>
.cIndex{
    .oprate{
        display: flex;
        align-items: flex-end;
    }
    .imgShow{
        position: relative;
        border: solid;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            position: absolute;
            z-index: -1;
        }
        .row{
            display: flex;
            //border: solid;
            //width: 100%;
            flex-wrap: wrap;
        }
    }
}
</style>