<template>
  <div class="cCell" :style="{width:size+'px',height:size*1.5+'px'}">
    <div class="num" v-if="ncsList" :style="{width:size+'px',height:size*0.5+'px'}">
      <canvas v-for="i of ncsList.length" :width="size*0.5+'px'" :height="size*0.5+'px'" :key="'k'+i" :ref="'num'+i"></canvas>
    </div>
    <canvas @click="cCellClick" ref="cChar" :width="size+'px'" :height="size+'px'"></canvas>
    <lh-dialog v-if="ncsList" :visible.sync="visible">
      <div>
        <lh-radio v-for="(ncs,i) of ncsList" :key="ncs.id" v-model="currentNcsIndex" :radioValue="i">笔画{{ i+1 }}</lh-radio>
      </div>
      <div>
        字码：
        <lh-select v-model="ncsList[currentNcsIndex].numCode">
          <lh-option v-for="i of 10" :key="i" :value="i-1">{{ i-1 }}</lh-option>
        </lh-select>
        <!-- 临时，后面要删除此按钮 -->
        <lh-button @click="deleteChar">删除</lh-button>
      </div>
      <c-editor @save="save" :cChar="cChar" :oprateList="getOprateList(currentNcsIndex)"></c-editor>
    </lh-dialog>
  </div>
</template>

<script>
import {uInitCChar,uUpdateColor,uExecuteOprate,punctuation} from "../utils/index";
import request from "../../../utils/request"
import CEditor from './CEditor.vue';
import qs from "qs";
export default {
  components: { CEditor },
  name:"CCell",
  data(){
    return{
      charId:null,
      ncsList:null,
      visible:false,
      currentNcsIndex:0
    }
  },
  props:{
    cChar:{
      type:String,
      default:null
    },
    size:{
      type:Number,
      default:40
    },
    numNum:{
      type:Number,
      default:0
    }
  },
  methods:{
    cCellClick:function(){
      if(this.numNum==0){
        return;
      }
      this.currentNcsIndex=0;
      this.visible=true;
    },
    getOprateList:function(index){
      if(this.ncsList==null||index>=this.ncsList.length){
        return null;
      }
      let strokes=this.ncsList[index].strokes;
      if(strokes==null){
        return null;
      }
      let oprateList=JSON.parse(strokes);
      return oprateList;
    },
    initNum:function(canvas,num){
      canvas.style.background="rgba(255,255,255,0)";
      let ctx=canvas.getContext("2d");
      ctx.clearRect(0,0,canvas.width,canvas.height);
      // 将文本绘制到画布上
      ctx.font = "bold "+canvas.height*1.2+"px '楷体'";
      //ctx.font =canvas.height+"px '楷体'";
      ctx.fillStyle = 'black';
      ctx.fillText(num, 0.2*canvas.width,canvas.height*0.9);
      uUpdateColor(canvas,"r");
    },
    drawNum:async function(){
      if(this.ncsList==null){
        return
      }
      for(let i=0;i<this.ncsList.length;i++){
        this.$nextTick(()=>{
          this.initNum(this.$refs["num"+(i+1)][0],this.ncsList[i].numCode);
        })
      }
    },
    getNumCodeStrokesList:async function(){
      // this.numList=[];
      // let i=0;
      // while(i<this.numNum){
      //   this.numList.push(8);
      //   i++;
      // }
      if(this.cChar==null||this.cChar==""||punctuation.test(this.cChar)||this.numNum==0){
        return;
      }
      let ncsdList=[];
      let that=this;
      await request.post("/cChar/addCChar",qs.stringify({cChar:this.cChar})).then((res)=>{
        console.log("data",res.data);
        that.charId=res.data.id;
        ncsdList=res.data.numCodeStrokesList;
      });

      let i=0;
      this.ncsList=[];
      while(i<this.numNum){
        console.log("length",ncsdList.length);
        if(i<ncsdList.length){
          this.ncsList.push(ncsdList[i]);
        }else{
          let ncs={
            id:null,
            numCode:0,
            ccharId:this.charId,
            strokes:null
          }
          this.ncsList.push(ncs);
        }
        i++;
      }
    },
    save:function(operateList){
      if(this.currentNcsIndex>0&&this.ncsList[this.currentNcsIndex-1].id==null){
        /** 请先保存笔画last */
        this.$lhMessage("warn","请先保存笔画"+this.currentNcsIndex);
        return;
      }
      let ncs=this.ncsList[this.currentNcsIndex];
      ncs.strokes=JSON.stringify(operateList);
      this.$lhLoading.open("");
      let that=this;
      if(ncs.id!=null){
        request.post("/numCodeStrokes/updateNumCodeStrokes",ncs).then(()=>{
          that.$lhMessage("success","保存成功");
          that.drawNum();
          that.drawCChar(9);
        }).finally(()=>{
          that.$lhLoading.close();
        })
      }else{
        request.post("/numCodeStrokes/addNumCodeStrokes",ncs).then(()=>{
          that.$lhMessage("success","保存成功");
          that.drawNum();
          that.drawCChar(9);
        }).finally(()=>{
          that.$lhLoading.close();
        })
      }
    },
    drawCChar:function(strokes){
      uInitCChar(this.$refs.cChar,this.cChar);
      if(strokes==null){
        return true;
      }
      if(this.ncsList==null){
        return false;
      }
      for(let i=0;i<this.ncsList.length;i++){
        if(i>strokes||this.ncsList[i].strokes==null){
          break;
        }
        let canvas=document.createElement("canvas");
        canvas.width=this.size;
        canvas.height=this.size;
        uInitCChar(canvas,this.cChar);
        let oprate=JSON.parse(this.ncsList[i].strokes);
        uExecuteOprate(canvas,oprate);
        uUpdateColor(canvas,"g");
        let ctx=this.$refs.cChar.getContext("2d");
        ctx.drawImage(canvas,0,0);
      }
    },
    deleteChar:function(){
      this.ncsList.splice(this.currentNcsIndex,1);
      this.currentNcsIndex=0;
      this.drawCChar(9);
    },
    getNumCanvas:function(index){
      index=index+1;
      let numR=this.$refs['num'+index];
      if(numR==undefined){
        return null
      }
      return numR[0];
    },
    getCharCanvas:function(){
      return this.$refs.cChar;
    }
  },
  watch:{
    cChar:{
      immediate:true,
      handler(){
        this.$nextTick(async ()=>{
          //uInitCChar(this.$refs.cChar,this.cChar);
          await this.getNumCodeStrokesList();
          this.drawCChar(9);
          this.drawNum();
        })
      }
    },
    size:{
      immediate:true,
      handler(){
        this.$nextTick(()=>{
          //uInitCChar(this.$refs.cChar,this.cChar);
          this.drawCChar(9);
          this.drawNum();
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cCell{
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  //border: solid red;
  .num{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>